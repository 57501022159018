import { createGlobalStyle } from "styled-components"

export const theme = {
  colors: {
    // primary: "red",
    // secondaire: "black",
    // text: "black",
    // lightText: "gray",

    primary: "rgb(245, 148, 67)",
    secondaire: "#244985",
    text: "#244985",
    lightText: "#90a2c1",

    gray: "#979797",
    white: "white",
  },
}

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }
  *{margin: 0; padding: 0}
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 62.5%;
  }
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.9rem;
  }
  .text-blue{
    color: ${props => props.theme.colors.secondaire};
  }
  .b{
    border: 1px red solid;
  }
`
